// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Backdrop_fade-in__OLvVf {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n@keyframes Backdrop_pop-up__vmnqK {\n  0% {\n    transform: scale(0);\n  }\n  70% {\n    transform: scale(1.1);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n.Backdrop_backdrop__wOwUI {\n  height: 100vh;\n  width: 100vw;\n  top: 0;\n  left: 0;\n  position: absolute;\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  background-color: rgba(0, 0, 0, 0.85);\n  padding: 3rem;\n  z-index: 50000;\n  animation: Backdrop_fade-in__OLvVf 0.3s;\n}\n\n@keyframes Backdrop_spin__Z7qzQ {\n  0% {\n    transform: rotate(0);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.Backdrop_loader__3vS7h {\n  animation: Backdrop_spin__Z7qzQ 1s ease-in-out infinite;\n}", "",{"version":3,"sources":["webpack://./src/components/styles/Backdrop.module.scss"],"names":[],"mappings":"AAAA;EACC;IACC,UAAA;EACA;EAED;IACC,UAAA;EAAA;AACF;AAGA;EACC;IACC,mBAAA;EADA;EAID;IACC,qBAAA;EAFA;EAKD;IACC,mBAAA;EAHA;AACF;AAMA;EACC,aAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,qCAAA;EACA,aAAA;EACA,cAAA;EACA,uCAAA;AAJD;;AAOA;EACC;IACC,oBAAA;EAJA;EAOD;IACC,yBAAA;EALA;AACF;AAQA;EACC,uDAAA;AAND","sourcesContent":["@keyframes fade-in {\n\t0% {\n\t\topacity: 0;\n\t}\n\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n@keyframes pop-up {\n\t0% {\n\t\ttransform: scale(0);\n\t}\n\n\t70% {\n\t\ttransform: scale(1.1);\n\t}\n\n\t100% {\n\t\ttransform: scale(1);\n\t}\n}\n\n.backdrop {\n\theight: 100vh;\n\twidth: 100vw;\n\ttop: 0;\n\tleft: 0;\n\tposition: absolute;\n\tdisplay: grid;\n\talign-items: center;\n\tjustify-items: center;\n\tbackground-color: rgba(0, 0, 0, 0.85);\n\tpadding: 3rem;\n\tz-index: 50000;\n\tanimation: fade-in 0.3s;\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0);\n\t}\n\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.loader {\n\tanimation: spin 1s ease-in-out infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "Backdrop_backdrop__wOwUI",
	"fade-in": "Backdrop_fade-in__OLvVf",
	"loader": "Backdrop_loader__3vS7h",
	"spin": "Backdrop_spin__Z7qzQ",
	"pop-up": "Backdrop_pop-up__vmnqK"
};
export default ___CSS_LOADER_EXPORT___;
