import { createContext, useState, useEffect } from "react";
import { getRequest } from "../utils/axios";

import axios from "axios";

import config from "../config";

export const userContext = createContext();

const UserDetailsProvider = (props) => {
  // const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    console.log("UserDetailsProvider+++");
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/da/admin`, {
          // headers: {
          //   authorization: `Bearer ${localStorage.getItem("authToken")}`,
          // },
          withCredentials: true,
        });

        console.log("response.data++++", response);
        setUserDetails(response.data);
        if (response.error) {
          console.log(response.error);
        } else {
          console.log(response.data);
        }
      } catch (error) {
        console.log("ERROR++++");
        console.log(error);
        // navigate("/");

        const updateRefreshToken = async () => {
          try {
            console.log("is getting HERE::::");
            // const result = await axios.get("da/refreshToken", {
            const result = await axios.get(`${config.apiUrl}/da/refreshToken`, {
              // headers: {
              //   authorization: `Bearer ${localStorage.getItem("authToken")}`,
              // },
              withCredentials: true,
            });

            console.log("updateRefreshToken result:", result);
            // setUserDetails(result);
            return result;
          } catch (error) {
            console.log("updateRefreshToken result ERROR:", error);
          }
        };

        // console.log("updateRefreshToken:", updateRefreshToken());
        updateRefreshToken();
      }
    };
    fetchUser();
  }, []);

  // const res = await getRequest({ url: `da/admin/` });
  //     setUserDetails(res.data.data);

  // useEffect(() => {
  //   async function fetchCookies() {
  //     const getToken = cookies.get("authToken");
  //     const getRefreshToken = cookies.get("refreshToken");
  //     setCookies({ authToken: getToken, refreshToken: getRefreshToken });
  //   }
  //   fetchCookies();
  // }, []);

  return (
    <userContext.Provider value={[userDetails, setUserDetails]}>
      {props.children}
    </userContext.Provider>
  );
};

export default UserDetailsProvider;

// import { createContext, useState } from 'react';

// export const userContext = createContext();

// const UserDetailsProvider = (props) => {

//     const [userDetails, setUserDetails] = useState({});
//     console.log(`This is the user details: ${userDetails}`)

//     return (
//         <userContext.Provider value={[userDetails, setUserDetails]}>
//             {props.children}
//         </userContext.Provider>
//     );
// };

// export default UserDetailsProvider;
