// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile_username__L6yLw {\n  display: flex;\n  align-items: center;\n  margin-left: 0.5rem;\n  font-weight: 600;\n  font-size: 15px;\n}\n\n.profile_profileDisplay__ik\\+Zk {\n  margin-top: 1rem;\n}\n@media screen and (max-width: 768px) {\n  .profile_profileDisplay__ik\\+Zk {\n    margin-top: 30px;\n  }\n}\n\n.profile_inputContainer__AiiN9 {\n  border: 1px solid lightgray;\n}\n\n.profile_editBtn__RlHr4 {\n  border: none;\n  min-width: 100px;\n  color: rgb(173, 10, 10);\n  font-weight: 600;\n  background-color: lightgray;\n}\n\n.profile_input__Z6PKt {\n  border: none;\n  font-weight: 500 !important;\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/styles/profile.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;AAAI;EAHJ;IAIQ,gBAAA;EAGN;AACF;;AAAA;EACI,2BAAA;AAGJ;;AAAA;EACI,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,2BAAA;AAGJ;;AAAA;EAEI,YAAA;EACA,2BAAA;AAEJ","sourcesContent":[".username{\n    display: flex;\n    align-items: center;\n    margin-left: 0.5rem;\n    font-weight: 600;\n    font-size: 15px;\n}\n.profileDisplay{\n    margin-top: 1rem;\n\n    @media screen and (max-width:768px) {\n        margin-top: 30px;\n       \n    }\n}\n.inputContainer{\n    border: 1px solid lightgray;\n    // border-radius: 15px;\n}\n.editBtn{\n    border: none;\n    min-width: 100px;\n    color: rgb(173, 10, 10);\n    font-weight: 600;\n    background-color: lightgray;\n    // border-radius: 15px;\n}\n.input{\n    // border-radius: 15px;\n    border: none;\n    font-weight: 500 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"username": "profile_username__L6yLw",
	"profileDisplay": "profile_profileDisplay__ik+Zk",
	"inputContainer": "profile_inputContainer__AiiN9",
	"editBtn": "profile_editBtn__RlHr4",
	"input": "profile_input__Z6PKt"
};
export default ___CSS_LOADER_EXPORT___;
