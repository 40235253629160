// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_header__Z\\+0Rp {\n  display: flex;\n  flex-direction: row;\n  padding-top: 40px;\n  padding-left: 20px;\n  padding-right: 40px;\n  color: #238188;\n  border-bottom: 1px solid #2B949C;\n  padding-bottom: 20px;\n  margin-right: 40px;\n  margin-left: 30px;\n}\n\n.header_text__G20xb {\n  padding-left: 50px;\n}\n\n.header_title__NZQmF {\n  font-weight: bolder;\n  font-size: 30px;\n}\n\n.header_subTitle__hFKMQ {\n  font-size: 18px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/components/styles/header.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,gCAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EAEI,mBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,eAAA;EACA,gBAAA;AAGJ","sourcesContent":[".header{\n    display: flex;\n    flex-direction: row;\n    padding-top: 40px;\n    padding-left: 20px;\n    padding-right: 40px;\n    color: #238188;\n    border-bottom: 1px solid #2B949C;\n    padding-bottom: 20px;\n    margin-right: 40px;\n    margin-left: 30px;\n}\n.text{\n    padding-left: 50px;\n}\n.title{\n   \n    font-weight: bolder;\n    font-size: 30px;\n}\n.subTitle{\n    font-size: 18px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_header__Z+0Rp",
	"text": "header_text__G20xb",
	"title": "header_title__NZQmF",
	"subTitle": "header_subTitle__hFKMQ"
};
export default ___CSS_LOADER_EXPORT___;
