// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auth_image__dh8j9 {\n  height: 650px;\n  width: 100%;\n  object-fit: contain;\n}\n\n.auth_bannerImage__ABSNb {\n  padding-left: 50px;\n  padding-top: 80px;\n}\n\n.auth_childSection__pDowl {\n  display: flex;\n  align-items: center;\n}\n@media screen and (max-width: 768px) {\n  .auth_childSection__pDowl {\n    display: block;\n    padding-top: 20px;\n  }\n}\n\n.auth_logo__CO9rJ {\n  width: 80px;\n  height: 50px;\n  object-fit: contain;\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Layouts/styles/auth.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;AACJ;;AACA;EACI,kBAAA;EACA,iBAAA;AAEJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;AAAI;EAHJ;IAIQ,cAAA;IACA,iBAAA;EAGN;AACF;;AAAA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAGJ","sourcesContent":[".image{\n    height: 650px;\n    width: 100%;\n    object-fit: contain;  \n}\n.bannerImage{\n    padding-left: 50px;\n    padding-top: 80px;\n    // padding-bottom: 100px;\n    \n}\n.childSection{\n    display: flex;\n    align-items: center;\n    @media screen and (max-width:768px) {\n        display: block;\n        padding-top: 20px;\n        \n    }\n}\n.logo{\n    width: 80px;\n    height: 50px;\n    object-fit: contain;\n    margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "auth_image__dh8j9",
	"bannerImage": "auth_bannerImage__ABSNb",
	"childSection": "auth_childSection__pDowl",
	"logo": "auth_logo__CO9rJ"
};
export default ___CSS_LOADER_EXPORT___;
