// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home_sideBar__HJjhx {\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: #fafafa;\n  height: 100vh;\n  color: rgba(42, 55, 138, 0.75);\n  padding-top: 60px;\n  padding-bottom: 50px;\n  padding-left: 40px !important;\n  padding-right: 20px;\n}\n\n.home_mobileSideBar__ezhu5 {\n  background-color: #fafafa;\n  width: 60vw;\n}\n\n.home_mobileTop__GyTr8 {\n  padding-top: 30px;\n  padding-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Layouts/styles/home.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;EACA,6BAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;EACA,WAAA;AAEF;;AACA;EACE,iBAAA;EACA,kBAAA;AAEF","sourcesContent":[".sideBar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: #fafafa;\n  height: 100vh;\n  color: rgba(42, 55, 138, 0.75);\n  padding-top: 60px;\n  padding-bottom: 50px;\n  padding-left: 40px !important;\n  padding-right: 20px;\n}\n.mobileSideBar {\n  background-color: #fafafa;\n  width: 60vw;\n}\n\n.mobileTop {\n  padding-top: 30px;\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": "home_sideBar__HJjhx",
	"mobileSideBar": "home_mobileSideBar__ezhu5",
	"mobileTop": "home_mobileTop__GyTr8"
};
export default ___CSS_LOADER_EXPORT___;
